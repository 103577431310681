import { Link } from "gatsby"
import React, { ReactElement } from "react"

import { Layout, SEO } from "../components"
import * as styles from "./error.module.scss"

const InternalServerErrorPage = (): ReactElement => (
  <Layout>
    <SEO title="500: Internal Server Error" lang="ja" />
    <div className={styles.container}>
      <h4 className={styles.title}>
        サーバーで問題が発生したためページを表示できませんでした。
      </h4>
      <p className={styles.message}>しばらく時間をおいてからご利用ください。</p>
      <Link to="/" className={styles.topPageButton}>
        トップページへ
      </Link>
    </div>
  </Layout>
)

export default InternalServerErrorPage
